import React, { Fragment, useState } from 'react';
import "./Login.css";
import { Navigate, useNavigate } from 'react-router-dom';
import { Input } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from './../actions/auth';
import PropTypes from 'prop-types';

const Login = ({ login, isAuthenticated }) => {

    const [loginDetails, setLoginDetails] = useState({
        email: "",
        password: ""
    })

    const onChange = (e) => {
        setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        console.log("Login Details:", loginDetails)
        const { email, password } = loginDetails;
        login({ email, password })
    }

    if (isAuthenticated) {
        return <Navigate to="/" />
    }

    return (
        <Fragment>
            <div id='login'>
                <img className='login-bg' src='/images/sunset-beach.jpg' alt='sunset-beach-background'></img>
                <div className='login-form'>
                    <form onSubmit={(e) => onSubmit(e)}>
                        <h1 className='heading text-center'>Sign In</h1>
                        <div className='form-input'>
                            <label>Email ID: <span style={{ color: "red" }}>*</span></label>
                            <Input name='email' placeholder="Email Id" autoComplete="none" type='email' value={loginDetails.email} required onChange={(e) => onChange(e)} />
                        </div>
                        <div className='form-input'>
                            <label>Password: <span style={{ color: "red" }}>*</span></label>
                            <Input name='password' placeholder="Password" autoComplete="none" type='password' value={loginDetails.password} required onChange={(e) => onChange(e)} />
                        </div>
                        <div className='form-input'>
                            <button className='form-btn'>Login</button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

Login.prototype = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, { login })(Login);