import { Header } from "./components/Header";
import { SideNav } from "./components/SideNav";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Dashboard from "./pages/Dashboard";
import BookingDetails from "./pages/BookingDetails";
import EnquiryDetails from "./pages/EnquiryDetails";
import AgencyDetails from "./pages/AgencyDetails";
import AgencyLoginRequest from "./pages/AgencyLoginRequest";
import Login from "./pages/Login";
import { Provider } from "react-redux";
import store from "./store";
import { useEffect } from "react";
import { loadUser } from "./actions/auth";
import PrivateRoute from "./components/routing/PrivateRoute";
import { getAgentBookingDetails, getAgentRequestDetails } from "./actions/agency";
import { getBookingDetails, getEnquiryDetails } from "./actions/user";
import BulkDownload from "./pages/BulkDownload";
import Alert from './components/layout/Alert';
import Refund from "./pages/Refund";


function App() {

  useEffect(() => {
    store.dispatch(loadUser())
    store.dispatch(getAgentBookingDetails())
    store.dispatch(getAgentRequestDetails())
    store.dispatch(getBookingDetails())
    store.dispatch(getEnquiryDetails())
  },[])

  return (
    <Provider store={store}>
      <div className="App h-100">
        <BrowserRouter>
          <section className="h-100">
            <Alert />
            <Routes>
              <Route path="/" element={<PrivateRoute component={Dashboard} />} />
              <Route path="/booking-details" element={<PrivateRoute component={BookingDetails} />} />
              <Route path="/enquiry-details" element={<PrivateRoute component={EnquiryDetails} />} />
              <Route path="/agency-booking-details" element={<PrivateRoute component={AgencyDetails} />} />
              <Route path="/agency-login-request" element={<PrivateRoute component={AgencyLoginRequest} />} />
              <Route path="/bulk-download" element={<PrivateRoute component={BulkDownload} />} />
              <Route path="/refund" element={<PrivateRoute component={Refund} />} />
              <Route path="/login" element={<Login />} />
            </Routes>
          </section>
        </BrowserRouter>
      </div>
    </Provider>

  );
}

export default App;
