import React, { Fragment, useEffect, useState } from 'react';
import { Space, Table, Tag, message, Button, Modal } from 'antd';
import Header from '../components/Header';
import { SideNav } from '../components/SideNav';
import { connect } from 'react-redux';
import { getAgentRequestDetails, sendAgreement, sendApprove } from './../actions/agency';
import { PropTypes } from 'prop-types';
import emailjs from '@emailjs/browser';
import { WarningOutlined, SendOutlined, LikeOutlined, SafetyOutlined } from '@ant-design/icons';


const AgencyLoginRequest = ({ getAgentRequestDetails, sendAgreement, sendApprove, agent: { agentRequestDetails } }) => {

  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openAgreementModal, setOpenAgreementModal] = useState(false);
  const [agentAgreementID, setAgentAgreementID] = useState({});
  const [modalTextId, setModalTextId] = useState(null);

  const showModal = (agentId) => {
    setOpen(true);
    setModalTextId(agentId);
  };

  const showAgreementModal = (agent) => {
    setOpenAgreementModal(true);
    setAgentAgreementID(agent);
  };

  const handleOk = (text) => {
    setLoading(true);
    sendApprove(text);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleAGModelCancel = () => {
    setOpenAgreementModal(false);
  };

  useEffect(() => {
    getAgentRequestDetails();
  }, [])

  let requestedAgentNames = [...new Set(agentRequestDetails?.map(agents => agents.agent_name))].map(agent => ({
    text: agent, value: agent
  }));

  let requestedAgentCompanyNames = [...new Set(agentRequestDetails?.map(agents => agents.agent_company_name))].map(agent => ({
    text: agent, value: agent
  }));

  let requestedAgentCities = [...new Set(agentRequestDetails?.map(agents => agents.city))].map(city => ({
    text: city, value: city
  }));

  let requestedAgentPhno = [...new Set(agentRequestDetails?.map(agents => agents.phone_number))].map(phno => ({
    text: phno, value: phno
  }));


  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const columns = [
    {
      title: 'Name',
      dataIndex: 'agent_name',
      key: 'agent_name',
      filters: requestedAgentNames,
      filterSearch: true,
      onFilter: (value, record) => {
        // Perform an exact match on clientName
        return record.agent_name.toLowerCase() === value.toLowerCase();
      },
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Agency Name',
      dataIndex: 'agent_company_name',
      key: 'agent_company_name',
      filters: requestedAgentCompanyNames,
      filterSearch: true,
      onFilter: (value, record) => {
        // Perform an exact match on clientName
        return record.agent_company_name.toLowerCase() === value.toLowerCase();
      },
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'GST Number',
      dataIndex: 'gst_number',
      key: 'gst_number',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Email ID',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      filters: requestedAgentPhno,
      onFilter: (value, record) => {
        return record.phone_number === value;
      },
      filterSearch: true,
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      filters: requestedAgentCities,
      filterSearch: true,
      onFilter: (value, record) => {
        // Perform an exact match on clientName
        return record.city.toLowerCase() === value.toLowerCase();
      },
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (_, { status }) => {
        let color;
        let icon;
        if (status === 'Registered') {
          color = 'error';
          icon = <WarningOutlined />
        }
        if (status === 'Agreement Sent') {
          color = 'warning';
          icon = <SendOutlined />;
        }
        if (status === 'Agreement Accepted') {
          color = 'processing';
          icon = <LikeOutlined />;
        }
        if (status === 'Verified') {
          color = 'success';
          icon = <SafetyOutlined />;
        }
        return (
          <Tag color={color} key={status} icon={icon}>
            {status.toUpperCase()}
          </Tag>
        )
      }
    },
    {
      title: 'Verify & Send',
      key: 'id',
      render: (text) => (
        <Space size="middle">
          {
            text.status != "Verified" ? <>
              <Button type="primary" onClick={() => showAgreementModal(text)}>
                Send Agreement
              </Button>
              {/* <Button type="primary" disabled={text.status == "Agreement Accepted" || text.status == "Verified" ? false : true} onClick={() => sendApprove(text)} >
                Approve
              </Button> */}
              <Button type="primary" disabled={text.status == "Agreement Accepted" || text.status == "Verified" ? false : true} onClick={() => showModal(text.id)}>
                Approve
              </Button>
            </> :
              <>
                <Button danger>
                  Agreement Sent
                </Button>
                <Button danger>
                  Approved
                </Button>
              </>
          }

          {/* <button >Send Agreement</button> */}
          {/* <button disabled={text.status == "Agreement Accepted" ? false : true} onClick={() => sendApprove(text)}>Approve</button> */}
        </Space>
      ),
    },
  ];

  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const getColumnsWithFilterSorter = columns.map(column => ({
    ...column,
    filteredValue: filteredInfo[column.dataIndex] || null,
    sortOrder: sortedInfo.columnKey === column.dataIndex && sortedInfo.order,
  }));

  console.log(agentRequestDetails)




  return (
    <Fragment>
      <Header />
      <SideNav />
      <div className='app-content'>
        <div id='booking-details'>
          <div className='p-2'>
            <h1 className='title'>Agency Login Request</h1>
            <Button onClick={clearAll} className='mb-3'>Clear All Filters</Button>

            <Table
              // columns={columns}
              columns={getColumnsWithFilterSorter}
              dataSource={agentRequestDetails}
              scroll={{ x: 1300 }}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
      <Modal
        open={open}
        title="Do you want to Approve this Agent?"
        maskClosable={false}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => handleOk(modalTextId)}
          >
            Yes Approve
          </Button>,
        ]}
      >
        {modalTextId}
        {/* {modalTextId} agent id displayed for testing */}
        <p>While approving, Sea Gold admin shares the Username & Password to the Agent for Login.</p>
      </Modal>
      <Modal
        open={openAgreementModal}
        title="Do you really want to Send Agreement to this Agent?"
        maskClosable={false}
        onCancel={handleAGModelCancel}
        footer={[
          <Button key="back" onClick={handleAGModelCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => sendAgreement(agentAgreementID)}
          >
            Yes, Send Agreement
          </Button>,
        ]}
      >
        {/* {agentAgreementID.id} agent id displayed for testing */}
        <p>While clicking "Send Agreement", our Sea Gold Sea Walk admin shares the Travel Agent Agreement details over email with "URL & OTP" to the Agent's registered Email ID. </p>
        <p>If you want to Send Agreement, Just click the below "Yes, Send Agreement" button or else Click "Cancel".</p>
      </Modal>
      {contextHolder}
    </Fragment>
  )
}

AgencyLoginRequest.prototype = {
  getAgentRequestDetails: PropTypes.func.isRequired,
  sendAgreement: PropTypes.func.isRequired,
  sendApprove: PropTypes.func.isRequired,
  agent: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  agent: state.agency
})

export default connect(mapStateToProps, { getAgentRequestDetails, sendAgreement, sendApprove })(AgencyLoginRequest)