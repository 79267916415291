import { Document, Font, Image, Page, StyleSheet, Text, View, Link } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';

// Register font
// Font.register({ family: 'Roboto', src: source });

Font.registerHyphenationCallback(word => [word]);

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-regular-webfont.ttf',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-bold-webfont.ttf',
      fontWeight: "bold",
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-bolditalic-webfont.ttf',
      fontWeight: "bold italic",
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-light-webfont.ttf',
      fontWeight: "light",
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-lightitalic-webfont.ttf',
      fontWeight: "light italic",
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-medium-webfont.ttf',
      fontWeight: "medium",
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-mediumitalic-webfont.ttf',
      fontWeight: "medium italic",
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-thin-webfont.ttf',
      fontWeight: "thin",
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-thinitalic-webfont.ttf',
      fontStyle: "thin italic",
    },

  ]
}
)

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
    position: 'relative',
    padding: 10,
  },
  mainSection: {
    height: "90px",
    paddingBottom: 5,
    marginBottom: 5,
    display: 'flex',
    flexDirection: "row",
    borderBottom: "1px solid black"
  },
  logoSection: {
    width: "17%",
  },
  titleSection: {
    width: "52%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  addressSection: {
    width: "31%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  seaWalkLogo: {
    width: "80px",
    height: "80px",
    objectFit: 'contain',
  },
  title: {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: "bold",
    color: "#0A4D68",
    textAlign: "left"
  },
  subTitle: {
    fontFamily: "Roboto",
    fontSize: "15px",
    color: "#088395",
    textAlign: "left"
  },
  address: {
    fontFamily: "Roboto",
    fontSize: "10px",
  },
  bookedDetailsSection: {
    paddingTop: 5,
    paddingBottom: 2,
  },
  bookedDetailsTitle: {
    textAlign: "center",
    fontSize: "12px",
    fontWeight: "bold",
    color: "#0A4D68",
    paddingBottom: "10px",
  },
  bookedDetailsRow: {
    width: "50%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    padding: "0px",
    margin: "0px",
  },
  bookedLabel: {
    fontFamily: "Roboto",
    fontSize: "11px",
    paddingBottom: "0px",
  },
  bookedValue: {
    fontFamily: "Roboto",
    fontSize: "11px",
    paddingBottom: "0px",
    color: "black",
  },
  secondSection: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: 10,
  },
  userDetailsSection1: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
  },
  userDetailsSection2: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
  },
  userDetailsRow: {
    display: "flex",
    flexDirection: "row",
  },
  userDetailsLabel: {
    width: "18%",
    fontFamily: "Roboto",
    fontSize: "11px",
  },
  userDetailsColon: {
    width: "2%",
    fontFamily: "Roboto",
    fontSize: "11px",
  },
  userDetailsValue: {
    width: "80%",
    fontFamily: "Roboto",
    fontSize: "11px",
  },
  bookedDetailsLabel: {
    width: "35%",
    fontFamily: "Roboto",
    fontSize: "11px",
  },
  bookedDetailsColon: {
    width: "2%",
    fontFamily: "Roboto",
    fontSize: "11px",
  },
  bookedDetailsValue: {
    width: "63%",
    fontFamily: "Roboto",
    fontSize: "11px",
  },
  tableSection: {
    width: "70%",
    borderLeft: "1px solid gray",
    borderRight: "1px solid gray"
  },
  tableHeadingRow: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#0A4D68",
  },
  tableHeadingSno: {
    width: "13%",
    fontFamily: "Roboto",
    fontSize: "10px",
    padding: "3px 7px",
    color: "white",
    borderRight: "1px solid gray"
  },
  tableHeadingName: {
    width: "47%",
    fontFamily: "Roboto",
    fontSize: "10px",
    padding: "3px 7px",
    color: "white",
    borderRight: "1px solid gray"
  },
  tableHeadingGender: {
    width: "20%",
    fontFamily: "Roboto",
    fontSize: "10px",
    padding: "3px 7px",
    color: "white",
    borderRight: "1px solid gray"
  },
  tableHeadingAge: {
    width: "20%",
    fontFamily: "Roboto",
    fontSize: "10px",
    padding: "3px 7px",
    color: "white"
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid gray",
  },
  sno: {
    width: "13%",
    fontFamily: "Roboto",
    fontSize: "10px",
    padding: "3px 7px",
    color: "black",
    borderRight: "1px solid gray"
  },
  name: {
    width: "47%",
    fontFamily: "Roboto",
    fontSize: "10px",
    padding: "3px 7px",
    color: "black",
    borderRight: "1px solid gray"
  },
  gender: {
    width: "20%",
    fontFamily: "Roboto",
    fontSize: "10px",
    padding: "3px 7px",
    color: "black",
    borderRight: "1px solid gray"
  },
  age: {
    width: "20%",
    fontFamily: "Roboto",
    fontSize: "10px",
    padding: "3px 7px",
    color: "black",
  },
  footerCredits: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  footerCreditsImage: {
    maxWidth: "100px",
    maxHeight: "60px",
  },
});


// Create Document Component
const MyDocument = ({ bookingDetails }) => {

  const defaultData = bookingDetails;
  const options = {
    timeZone: 'Asia/Kolkata', // Set the time zone to IST
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };


  return (<>
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.mainSection}>
          <View style={styles.logoSection}>
            <Image style={styles.seaWalkLogo} src="/images/sea gold.jpg" alt="sea-walk-logo"></Image>
          </View>
          <View style={styles.titleSection}>
            <Text style={styles.title}>SEA GOLD SEA WALK</Text>
            <Text style={styles.subTitle}>Sea Walking in Andaman Islands</Text>
            <Text style={styles.address}>Khizar Ocean Logistics Private Limited </Text>
            <Text style={styles.address}>Customer Care: +91 75869-04999, +91 76950-95641,</Text>
            <Text style={styles.address}>Ph.No: +91 99320-80340,</Text>
            {/* <Text style={styles.address}>Email: contact@seagoldseawalk.com</Text> */}
          </View>
          <View style={styles.addressSection}>
            <Text style={[styles.address, { fontWeight: "bold" }]}>Sea Gold Sea Walk</Text>
            <Text style={styles.address}>1st Floor, Sri Ram Complex,</Text>
            <Text style={styles.address}>Opp: Old Taxi Stand,</Text>
            <Text style={styles.address}>Aberdeen Bazaar, Port Blair,</Text>
            <Text style={styles.address}>Andaman and Nicobar Islands - 744101.</Text>
            {/* <Text style={styles.address}>Ph.No: +91 99320-80340,</Text> */}
            <Text style={styles.address}>Email: contact@seagoldseawalk.com</Text>
          </View>
        </View>
        <View style={styles.bookedDetailsSection}>
          <Text style={styles.bookedDetailsTitle}>Ticket Booked For {defaultData.no_of_tourist} Persons</Text>
          {/* <View style={{ display: "flex", flexDirection: "row" }}>
            <View style={styles.bookedDetailsRow}>
              <Text style={[styles.bookedLabel, { fontWeight: "bold", }]}>Booked ID : </Text>
              <Text style={styles.bookedValue}>{defaultData.bookingID && defaultData.bookingID}</Text>
            </View>
            <View style={styles.bookedDetailsRow}>
              <Text style={[styles.bookedLabel, { fontWeight: "bold", }]}>Booked On : </Text>
              <Text style={styles.bookedValue}>{defaultData.bookedOn && new Intl.DateTimeFormat('en-US', options).format(new Date())}</Text>
            </View>
          </View> */}
        </View>
        <View style={styles.secondSection}>
          <View style={styles.userDetailsSection1}>
            <View style={styles.userDetailsRow}>
              <Text style={[styles.userDetailsLabel, { fontWeight: "bold", }]}>Booked ID</Text>
              <Text style={styles.userDetailsColon}>:</Text>
              <Text style={styles.userDetailsValue}>{defaultData.booking_id && defaultData.booking_id}</Text>
            </View>
            <View style={styles.userDetailsRow}>
              <Text style={styles.userDetailsLabel}>Name</Text>
              <Text style={styles.userDetailsColon}>:</Text>
              <Text style={styles.userDetailsValue}>{defaultData.user_name && defaultData.user_name}</Text>
            </View>
            <View style={styles.userDetailsRow}>
              <Text style={styles.userDetailsLabel}>Email ID</Text>
              <Text style={styles.userDetailsColon}>:</Text>
              <Text style={styles.userDetailsValue}>{defaultData.email && defaultData.email}</Text>
            </View>
            <View style={styles.userDetailsRow}>
              <Text style={styles.userDetailsLabel}>Ph.No</Text>
              <Text style={styles.userDetailsColon}>:</Text>
              <Text style={styles.userDetailsValue}>{defaultData.phone_number && defaultData.phone_number}</Text>
            </View>
            <View style={styles.userDetailsRow}>
              <Text style={styles.userDetailsLabel}>City</Text>
              <Text style={styles.userDetailsColon}>:</Text>
              <Text style={styles.userDetailsValue}>{defaultData.city && defaultData.city}</Text>
            </View>
          </View>
          <View style={styles.userDetailsSection2}>
            <View style={styles.userDetailsRow}>
              <Text style={[styles.bookedDetailsLabel, { fontWeight: "bold", }]}>Booked On</Text>
              <Text style={styles.bookedDetailsColon}>:</Text>
              <Text style={styles.bookedDetailsValue}>{defaultData.date_time && new Intl.DateTimeFormat('en-US', options).format(new Date(defaultData.date_time))}</Text>
              {/* <Text style={styles.bookedDetailsValue}>{defaultData.date_time && new Intl.DateTimeFormat('en-US', options).format(new Date(defaultData.date_time))}</Text> */}
            </View>
            <View style={styles.userDetailsRow}>
              <Text style={styles.bookedDetailsLabel}>Sea Walk Date</Text>
              <Text style={styles.bookedDetailsColon}>:</Text>
              <Text style={styles.bookedDetailsValue}>{defaultData.seawalk_date && defaultData.seawalk_date}</Text>
            </View>
            <View style={styles.userDetailsRow}>
              <Text style={styles.bookedDetailsLabel}>Slot (Sea Walk Time)</Text>
              <Text style={styles.bookedDetailsColon}>:</Text>
              <Text style={styles.bookedDetailsValue}>{defaultData.booked_slot && defaultData.booked_slot}</Text>
            </View>
            <View style={styles.userDetailsRow}>
              <Text style={styles.bookedDetailsLabel}>No.Of Travellers</Text>
              <Text style={styles.bookedDetailsColon}>:</Text>
              <Text style={styles.bookedDetailsValue}>{defaultData.no_of_tourist && defaultData.no_of_tourist} {defaultData.no_of_tourist > 1 ? "Persons" : "Person"}</Text>
            </View>
            <View style={styles.userDetailsRow}>
              <Text style={styles.bookedDetailsLabel}>Paid Amount</Text>
              <Text style={styles.bookedDetailsColon}>:</Text>
              <Text style={styles.bookedDetailsValue}>Rs. {defaultData.total_amount && defaultData.total_amount}</Text>
            </View>
          </View>
        </View>
        <View style={styles.tableSection}>
          <View style={styles.tableHeadingRow}>
            <Text style={styles.tableHeadingSno}>S.No</Text>
            <Text style={styles.tableHeadingName}>Name</Text>
            <Text style={styles.tableHeadingGender}>Gender</Text>
            <Text style={styles.tableHeadingAge}>Age</Text>
          </View>
          <View>
            {
              defaultData.tourist_details && defaultData.tourist_details.map((traveler, index) => {
                return (
                  <>
                    <View style={styles.tableRow}>
                      <Text style={styles.sno}>{index + 1}</Text>
                      <Text style={styles.name}>{traveler.travelerName}</Text>
                      <Text style={styles.gender}>{traveler.travelerGender}</Text>
                      <Text style={styles.age}>{traveler.travelerAge}</Text>
                    </View>
                  </>
                )
              })
            }
          </View>
        </View>
        <View style={styles.footerCredits} src="https://www.theinvoice.in/" >
          <Text style={{ fontFamily: "Roboto", fontSize: "8px", fontWeight: "bold" }} src="https://www.theinvoice.in/">
            Generated by
          </Text>
          <Image style={styles.footerCreditsImage} src="/images/invoice-in-logo.png" alt="invoice-logo"></Image>
        </View>
      </Page>
    </Document>
  </>)
};

export default MyDocument;