import axios from 'axios';
import { GET_BOOKING_DETAILS_BY_DATE, GET_BOOKING_DETAILS_BY_DATE_ERROR } from './types';
import { setAlert } from './alert';

// Get Booking Details By Date
export const getBookingDetailsByDate = ({ fromDate, toDate, userType }) => async (dispatch) => {

    const API = axios.create({
        baseURL: "https://admin.seagoldseawalk.com",
    });

    try {
        const res = await API.post("https://www.seagoldseawalk.com/user_webservice/Login/getBookingDetailsByDate", { fromDate, toDate, userType }).then((res) => {
            console.log(res.data)
            if (res?.data) {
                dispatch({
                    type: GET_BOOKING_DETAILS_BY_DATE,
                    payload: res.data
                })
                dispatch(setAlert("Form Submited Successfully!", "success"))
            }
        });
    } catch (err) {

        if (!err?.response) {
            console.log("no server response");
        }

        if (err.response.data.error_code == 0) {
            dispatch({
                type: GET_BOOKING_DETAILS_BY_DATE_ERROR,
                payload: { msg: err.response.data.msg, status: err.response.status }
            })
            err.response.data.error && dispatch(setAlert(err.response.data.error, "error"))
        }
    }
}