import React, { Fragment, useState } from 'react';
import "./Dashboard.css";
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Line } from '@ant-design/plots';
import TotalSales from "../total-sales.json"
import Header from '../components/Header';
import { SideNav } from '../components/SideNav';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

const Dashboard = ({ user, agent }) => {

  const formattedBookingData = user.bookingDetails?.map((booking) => ({
    Date: booking.seawalk_date, // Assuming this is the correct date field
    scales: 1, // You might need to adjust this based on your data
  }));


  const [data, setData] = useState([...TotalSales].reverse());

  const navigate = useNavigate();

  const onClickCard = (cardId) => {
    navigate(cardId);
    localStorage.removeItem('activeMenu');
    // Save the active menu to localStorage
    localStorage.setItem('activeMenu', cardId);
  }

  const config = {
    data: formattedBookingData,
    padding: 'auto',
    xField: 'Date',
    yField: 'scales',
    xAxis: {
      tickCount: 5,
    },
    slider: {
      start: 0,
      end: 1,
    },
  };

  let totalAmount = 0;
  user.bookingDetails?.map((bookings) => {
    totalAmount = Number(bookings.total_amount) + totalAmount;
  })

  let totalAgentSales = 0;
  agent.agentBookingDetails?.map((bookings) => {
    totalAgentSales = Number(bookings.total_amount) + totalAgentSales;
  })

  return (
    <Fragment>
      <Header />
      <SideNav />
      {
        !user.bookingDetails || !agent.agentBookingDetails ? <h1>Loading</h1> : <div className='app-content'>
          <div id='dashboard'>
            <div className='row m-0'>
              <div className='col-md-6 p-3'>
                <div className='dashboard-card customer-bookings row p-4'>
                  <div className='col-sm-7 d-flex flex-column justify-content-center align-items-start'>
                    <p className='dashboard-card_sub-heading'>Customer</p>
                    <h1 className='dashboard-card_heading'>Total Bookings</h1>
                  </div>
                  <div className='col-sm-5 d-flex justify-content-end align-items-center'>
                    <h1 className='dashboard-card_number text-end'>{user?.bookingDetails.length}</h1>
                  </div>
                </div>
              </div>
              <div className='col-md-6 p-3'>
                <div className='dashboard-card customer-bookings row p-4'>
                  <div className='col-sm-7 d-flex flex-column justify-content-center align-items-start'>
                    <p className='dashboard-card_sub-heading'>Customer</p>
                    <h1 className='dashboard-card_heading'>Total Sales</h1>
                  </div>
                  <div className='col-sm-5 d-flex justify-content-end align-items-center'>
                    <h1 className='dashboard-card_number text-end'>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(totalAmount && totalAmount,)}</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className='row m-0 mt-4'>
              <div className='col-md-6 p-3'>
                <div className='dashboard-card agent-bookings row p-4'>
                  <div className='col-sm-7 d-flex flex-column justify-content-center align-items-start'>
                    <p className='dashboard-card_sub-heading'>Travel Agent</p>
                    <h1 className='dashboard-card_heading'>Total Bookings</h1>
                  </div>
                  <div className='col-sm-5 d-flex justify-content-end align-items-center'>
                    <h1 className='dashboard-card_number text-end'>{agent?.agentBookingDetails.length}</h1>
                  </div>
                </div>
              </div>
              <div className='col-md-6 p-3'>
                <div className='dashboard-card agent-bookings row p-4'>
                  <div className='col-sm-7 d-flex flex-column justify-content-center align-items-start'>
                    <p className='dashboard-card_sub-heading'>Travel Agent</p>
                    <h1 className='dashboard-card_heading'>Total Sales</h1>
                  </div>
                  <div className='col-sm-5 d-flex justify-content-end align-items-center'>
                    <h1 className='dashboard-card_number text-end'>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(totalAgentSales && totalAgentSales)}</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className='row m-0 mt-4'>
              <div className='col-md-6 p-3'>
                <div className='dashboard-card all-bookings row p-4'>
                  <div className='col-sm-7 d-flex flex-column justify-content-center align-items-start'>
                    <p className='dashboard-card_sub-heading'>All</p>
                    <h1 className='dashboard-card_heading'>All Bookings</h1>
                  </div>
                  <div className='col-sm-5 d-flex justify-content-end align-items-center'>
                    <h1 className='dashboard-card_number text-end'>{user?.bookingDetails.length + agent?.agentBookingDetails.length}</h1>
                  </div>
                </div>
              </div>
              <div className='col-md-6 p-3'>
                <div className='dashboard-card all-bookings row p-4'>
                  <div className='col-sm-7 d-flex flex-column justify-content-center align-items-start'>
                    <p className='dashboard-card_sub-heading'>All</p>
                    <h1 className='dashboard-card_heading'>All Sales</h1>
                  </div>
                  <div className='col-sm-5 d-flex justify-content-end align-items-center'>
                    <h1 className='dashboard-card_number text-end'>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(totalAmount + (totalAgentSales && totalAgentSales))}</h1>
                  </div>
                </div>
              </div>
            </div>
            <br></br>
            {/* <div id='chart-sections'>
            <div className='row m-0'>
              <div className='col-md-6 p-3'>
                <div className='chart-card'>
                  <div className='chart'>
                    <Line {...config} />
                  </div>
                  <h1 className='dashboard-card_heading text-center'>User Booking History</h1>
                </div>
              </div>
              <div className='col-md-6 p-3'>
                <div className='chart-card'>
                  <div className='chart'>
                    <Line {...config} />
                  </div>
                  <h1 className='dashboard-card_heading text-center'>Travel Agency Booking History</h1>
                </div>
              </div>
            </div>
          </div> */}
          </div>
        </div>
      }
    </Fragment>
  )
}

Dashboard.prototype = {
  user: PropTypes.object.isRequired,
  agent: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  user: state.user,
  agent: state.agency
})

export default connect(mapStateToProps)(Dashboard);

{/* <div className='row m-0'>
          <div className='col-md-3'>
            <div className='dashboard-card' onClick={() => onClickCard('/booking-details')}>
              <h1 className='dashboard-card_heading'>Booking Details</h1>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='dashboard-card' onClick={() => onClickCard('/enquiry-details')}>
              <h1 className='dashboard-card_heading'>Enquiry Details</h1>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='dashboard-card' onClick={() => onClickCard('/agency-details')}>
              <h1 className='dashboard-card_heading'>Agency Details</h1>
            </div>
          </div>
          <div className='col-md-3'>
            
          </div>
        </div> */}