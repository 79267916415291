import React, { Fragment, useState } from 'react';
import "./Header.css";
import { Link, NavLink } from 'react-router-dom';
import { logout } from './../actions/auth';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Button, Drawer } from 'antd';
import { DownloadOutlined, ReloadOutlined } from '@ant-design/icons';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { FloatButton, Modal } from 'antd';


const Header = ({ logout, auth: { isAuthenticated, loading } }) => {

  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const [activeMenu, setActiveMenu] = useState(null);

  const onClickMenu = (menuId) => {
    setActiveMenu(menuId);
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  return (
    <Fragment>
      <header>
        <div className='header'>
          <div className='d-flex align-items-center d-lg-none'>
            <a href="/" >
              <img className="logo" src='/images/sea-gold-logo.jpg' alt="logo"></img>
            </a>
            <img className="d-block d-lg-none hamburger-menu" src='/images/hamburger-menu.png' alt="logo" onClick={showDrawer}></img>
            <Drawer title="SEA GOLD SEA WALK" placement="left" onClose={onClose} open={open}>
              <ul className='p-0'>
                <li className='mb-2'><NavLink className={activeMenu === 'Dashboard' ? 'menu-link active' : 'menu-link bulk-menu'} to='/' onClick={() => onClickMenu('Dashboard')}>Dashboard</NavLink></li>
                <div className='menu-group customer'>
                  <h6>Customer</h6>
                  <li><NavLink className={activeMenu === 'booking-details' ? 'menu-link active' : 'menu-link'} to='/booking-details' onClick={() => onClickMenu('booking-details')}>Booking Details</NavLink></li>
                  <li><NavLink className={activeMenu === 'enquiry-details' ? 'menu-link active' : 'menu-link'} to='/enquiry-details' onClick={() => onClickMenu('enquiry-details')}>Enquiry Details</NavLink></li>
                </div>
                {/* <hr></hr> */}
                <div className='menu-group travel-agency'>
                  <h6>Travel Agency</h6>
                  <li><NavLink className={activeMenu === 'agency-booking-details' ? 'menu-link active' : 'menu-link'} to='/agency-booking-details' onClick={() => onClickMenu('agency-booking-detailst')}>Booking Details</NavLink></li>
                  <li><NavLink className={activeMenu === 'agency-login-request' ? 'menu-link active' : 'menu-link'} to='/agency-login-request' onClick={() => onClickMenu('agency-login-request')}>Agency Request</NavLink></li>
                </div>
                <li><NavLink className={activeMenu === 'bulk-download' ? 'menu-link active' : 'menu-link bulk-menu'} to='/bulk-download' onClick={() => onClickMenu('bulk-download')}>Bulk Download <DownloadOutlined /></NavLink></li>
                {/* <li><NavLink className={activeMenu === 'refund' ? 'menu-link active' : 'menu-link bulk-menu'} to='/refund' onClick={() => onClickMenu('refund')}>Refund <ReloadOutlined /></NavLink></li> */}
              </ul>
            </Drawer>
          </div>
          <div></div>
          <div>
            <Button type="default" shape="circle" icon={<QuestionCircleOutlined />} onClick={showModal} />
            <div className="dropdown nav-link">

              <span><img className="user" src='/images/user.png' alt="logo"></img></span>
              {
                !loading && isAuthenticated ?
                  <div className="dropdown-content">
                    <Link to="#!" onClick={logout}>Logout</Link>
                  </div> : ""
              }
            </div>
          </div>


        </div>
      </header>
      <Modal title="For Technical Support" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p> If you have any doubts or clarrifications, do reach out to Development team at contact@zeek.digital</p>
      </Modal>
    </Fragment>
  )
}

Header.prototype = {
  logout: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
})

export default connect(mapStateToProps, { logout })(Header);