import axios from 'axios';
import { GET_BOOKING_DETAILS, GET_BOOKING_DETAILS_ERROR, GET_ENQUIRY_DETAILS, GET_ENQUIRY_DETAILS_ERROR } from './types';

// booking details
export const getBookingDetails = () => async (dispatch) => {

    const API = axios.create({
        baseURL: "https://admin.seagoldseawalk.com",
    });

    try {
        const res = await API.get("https://www.seagoldseawalk.com/user_webservice/Login/userBookingDetails").then((res) => {
            console.log(res)
            if (res?.data) {
                dispatch({
                    type: GET_BOOKING_DETAILS,
                    payload: res.data
                })
            }
        });
    } catch (err) {

        if (!err?.response) {
            console.log("no server response");
        }

        if (err.response.data.error_code == 0) {
            dispatch({
                type: GET_BOOKING_DETAILS_ERROR,
                payload: { msg: err.response.data.msg, status: err.response.status }
            })
        }
    }
}

// booking details
export const getEnquiryDetails = () => async (dispatch) => {

    const API = axios.create({
        baseURL: "https://admin.seagoldseawalk.com",
    });

    try {
        const res = await API.get("https://www.seagoldseawalk.com/user_webservice/Login/enquiryDetails").then((res) => {
            console.log(res)
            if (res?.data) {
                dispatch({
                    type: GET_ENQUIRY_DETAILS,
                    payload: res.data
                })
            }
        });
    } catch (err) {

        if (!err?.response) {
            console.log("no server response");
        }

        if (err.response.data.error_code == 0) {
            dispatch({
                type: GET_ENQUIRY_DETAILS_ERROR,
                payload: { msg: err.response.data.msg, status: err.response.status }
            })
        }
    }
}