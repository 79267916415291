import { GET_BOOKING_DETAILS_ERROR, GET_BOOKING_DETAILS, GET_ENQUIRY_DETAILS, GET_ENQUIRY_DETAILS_ERROR } from "../actions/types";

const initialState = {
    bookingDetails: null,
    enquiryDetails: null,
    loading: true,
    error: {}
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_BOOKING_DETAILS:
            return {
                ...state,
                bookingDetails: payload,
                loading: false,
            }
        case GET_BOOKING_DETAILS_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        case GET_ENQUIRY_DETAILS:
            return {
                ...state,
                enquiryDetails: payload,
                loading: false,
            }
        case GET_ENQUIRY_DETAILS_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state;
    }
}