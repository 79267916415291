import { GET_BOOKING_DETAILS_BY_DATE, GET_BOOKING_DETAILS_BY_DATE_ERROR } from "../actions/types";

const initialState = {
    bookingDetailsByDate: null,
    loading: true,
    error: null
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_BOOKING_DETAILS_BY_DATE:
            return {
                ...state,
                bookingDetailsByDate: payload,
                loading: false,
            }
        case GET_BOOKING_DETAILS_BY_DATE_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state;
    }
}