import axios from 'axios';
import { GET_AGENT_BOOKING_DETAILS, GET_AGENT_BOOKING_DETAILS_ERROR, GET_AGENT_REQUEST_DETAILS, GET_AGENT_REQUEST_DETAILS_ERROR } from './types';
import { setAlert } from './alert';

// Get Agent Booking Details
export const getAgentBookingDetails = () => async (dispatch) => {

    const API = axios.create({
        baseURL: "https://admin.seagoldseawalk.com",
    });

    try {
        const res = await API.get("https://www.seagoldseawalk.com/user_webservice/Login/agentBookingDetails").then((res) => {
            // console.log(res)
            if (res?.data) {
                dispatch({
                    type: GET_AGENT_BOOKING_DETAILS,
                    payload: res.data
                })
            }
        });
    } catch (err) {

        if (!err?.response) {
            console.log("no server response");
        }

        if (err.response.data.error_code == 0) {
            dispatch({
                type: GET_AGENT_BOOKING_DETAILS_ERROR,
                payload: { msg: err.response.data.msg, status: err.response.status }
            })
        }
    }
}

// Get Agent Request Details
export const getAgentRequestDetails = () => async (dispatch) => {

    const API = axios.create({
        baseURL: "https://admin.seagoldseawalk.com",
    });

    try {
        const res = await API.get("https://www.seagoldseawalk.com/user_webservice/Login/agentRequestDetails").then((res) => {
            // console.log(res)
            if (res?.data) {
                dispatch({
                    type: GET_AGENT_REQUEST_DETAILS,
                    payload: res.data
                })
            }
        });
    } catch (err) {

        if (!err?.response) {
            console.log("no server response");
        }

        if (err.response.data.error_code == 0) {
            dispatch({
                type: GET_AGENT_REQUEST_DETAILS_ERROR,
                payload: { msg: err.response.data.msg, status: err.response.status }
            })
        }
    }
}

// Send Agreement to Agent
export const sendAgreement = (agent) => async (dispatch) => {

    // console.log(agent.id, agent.email, agent.agent_name)

    const API = axios.create({
        baseURL: "https://admin.seagoldseawalk.com",
    });

    try {
        const res = await API.post("https://www.seagoldseawalk.com/user_webservice/Login/sendAgreement", { agentId: agent.id, agentEmail: agent.email, agentName: agent.agent_name }).then((res) => {
            console.log(res)
            if (res?.data) {
                console.log(res.data.msg);
                dispatch(setAlert(res.data.msg, "success"))
            }
        });
    } catch (err) {

        if (!err?.response) {
            console.log("no server response");
        }

        if (err.response.data.error_code == 0) {
            dispatch({
                type: GET_AGENT_REQUEST_DETAILS_ERROR,
                payload: { msg: err.response.data.msg, status: err.response.status }
            })
        }
    }
}

// Send Onboarding Details
export const sendApprove = (agent) => async (dispatch) => {

    console.log(agent)
    const API = axios.create({
        baseURL: "https://admin.seagoldseawalk.com",
    });

    try {
        const res = await API.post("https://www.seagoldseawalk.com/user_webservice/Login/sendApproved", { agentId: agent }).then((res) => {
            // console.log(res)
            if (res?.data) {
                // console.log(res.data.msg);
                dispatch(setAlert(res.data.msg, "success"))
            }

        });
    } catch (err) {

        if (!err?.response) {
            console.log("no server response");
        }

        if (err.response.data.error_code == 0) {
            dispatch({
                type: GET_AGENT_REQUEST_DETAILS_ERROR,
                payload: { msg: err.response.data.msg, status: err.response.status }
            })
        }
    }
}