import { LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT, USER_LOADED, AUTH_ERROR } from "./types";
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import { setAlert } from "./alert";

// load user 
export const loadUser = () => async (dispatch) => {
    if (localStorage.adminToken) {
        dispatch({
            type: USER_LOADED,
            payload: { user: { email: "xxxx123@gmail.com" } }
        })
    } else {
        dispatch({
            type: AUTH_ERROR,
        })
    }
}

// login user
export const login = ({ email, password }) => async (dispatch) => {

    const API = axios.create({
        baseURL: "https://admin.seagoldseawalk.com",
    });

    try {
        const res = await API.post("https://www.seagoldseawalk.com/webservice/Login/login", {
            email,
            password,
        }).then((res) => {
            console.log(res.data.data.token)
            if (res?.data.data.token) {
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: { adminToken: res?.data.data.token }
                })
                dispatch(loadUser())
                dispatch(setAlert("Welcome, you have Logged in Successfully!", "success"))
                console.log("submited");
            }
        });
    } catch (err) {
        if (!err?.response) {
            console.log("no server response");
        } else {
            dispatch({
                type: LOGIN_FAIL,
            })
            err.response.data.error && dispatch(setAlert(err.response.data.error, "error"))
            console.log("registeration failed", err);
        }
    }

}

// logout / clear profile
export const logout = () => (dispatch) => {
    dispatch({ type: LOGOUT })
}