import { REFUND_ERROR, REFUND_DONE } from "../actions/types";

const initialState = {
    refundDetails: null,
    loading: true,
    error: null
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case REFUND_DONE:
            return {
                ...state,
                refundDetails: payload,
                loading: false,
            }
        case REFUND_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state;
    }
}