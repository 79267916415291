import axios from 'axios';
import { REFUND_DONE, REFUND_ERROR } from './types';
import { setAlert } from './alert';

// Get Booking Details By Date
export const refundPayment = (values) => async (dispatch) => {

    console.log(values);

    const API = axios.create({
        baseURL: "https://admin.seagoldseawalk.com",
    });

    try {
        const res = await API.post("https://www.seagoldseawalk.com/user_webservice/Login/userRefundPayment", values).then((res) => {
            console.log(res.data)
            if (res?.data) {
                dispatch({
                    type: REFUND_DONE,
                    payload: res.data
                })
                dispatch(setAlert("Form Submited Successfully!", "success"))
            }
        });
    } catch (err) {

        if (!err?.response) {
            console.log("no server response");
        }

        if (err.response.data.error_code == 0) {
            dispatch({
                type: REFUND_ERROR,
                payload: { msg: err.response.data.msg, status: err.response.status }
            })
            err.response.data.error && dispatch(setAlert(err.response.data.error, "error"))
        }
    }
}