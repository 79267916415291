import React, { Fragment, useEffect, useState } from 'react';
import { Space, Table, Tag, Button } from 'antd';
import Header from '../components/Header';
import { SideNav } from '../components/SideNav';
import { connect } from 'react-redux';
import { getEnquiryDetails } from './../actions/user';
import { PropTypes } from 'prop-types';


const EnquiryDetails = ({ getEnquiryDetails, user: { enquiryDetails } }) => {

  useEffect(() => {
    getEnquiryDetails()
  },[])

  let contactPersonsName = [...new Set(enquiryDetails?.map(enquiries => enquiries.name))].map(enquiry => ({
    text: enquiry, value: enquiry
  }));

  let contactPersonsPhno = [...new Set(enquiryDetails?.map(enquiries => enquiries.phoneNumber))].map(enquiry => ({
    text: enquiry, value: enquiry
  }));

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const options = {
    timeZone: 'Asia/Kolkata', // Set the time zone to IST
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      filters: contactPersonsName,
      filterSearch: true,
      onFilter: (value, record) => {
        // Perform an exact match on clientName
        return record.name.toLowerCase() === value.toLowerCase();
      },
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      filters: contactPersonsPhno,
      onFilter: (value, record) => {
        return record.phoneNumber === value;
      },
      filterSearch: true,
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
    },
    ,
    {
      title: 'Enquiry Date',
      dataIndex: 'date_time',
      key: 'date_time',
      render: (date) => <p className="m-0">{new Intl.DateTimeFormat('en-US', options).format(new Date(date))}</p>,
    },
  ];

  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const getColumnsWithFilterSorter = columns.map(column => ({
    ...column,
    filteredValue: filteredInfo[column.dataIndex] || null,
    sortOrder: sortedInfo.columnKey === column.dataIndex && sortedInfo.order,
  }));


  return (
    <Fragment>
      <Header />
      <SideNav />
      <div className='app-content'>
        <div id='booking-details'>
          <div className='p-2'>
            <h1 className='title'>Enquiry Details</h1>
            <Button onClick={clearAll} className='mb-3'>Clear All Filters</Button>
            <Table
              // columns={columns}
              columns={getColumnsWithFilterSorter}
              dataSource={enquiryDetails}
              scroll={{ x: 1300 }}
              onChange={onChange}
            />
          </div>
        </div>
      </div>

    </Fragment>

  )
}

EnquiryDetails.prototype = {
  getEnquiryDetails: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default connect(mapStateToProps, { getEnquiryDetails })(EnquiryDetails);