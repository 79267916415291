import { GET_AGENT_BOOKING_DETAILS_ERROR, GET_AGENT_BOOKING_DETAILS, GET_AGENT_REQUEST_DETAILS, GET_AGENT_REQUEST_DETAILS_ERROR} from "../actions/types";

const initialState = {
    agentBookingDetails: null,
    agentRequestDetails: null,
    loading: true,
    error: {}
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_AGENT_BOOKING_DETAILS:
            return {
                ...state,
                agentBookingDetails: payload,
                loading: false,
            }
        case GET_AGENT_BOOKING_DETAILS_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        case GET_AGENT_REQUEST_DETAILS:
            return {
                ...state,
                agentRequestDetails: payload,
                loading: false,
            }
        case GET_AGENT_REQUEST_DETAILS_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state;
    }
}