import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Space, Table, Tag, Input, Button, DatePicker } from 'antd';
import Header from '../components/Header';
import { SideNav } from '../components/SideNav';
import { connect } from 'react-redux';
import { getBookingDetails } from './../actions/user';
import { PropTypes } from 'prop-types';
import MyDocument from '../components/ui/DownloadPDF';
import { PDFDownloadLink } from '@react-pdf/renderer';
import "./Dashboard.css";
import dayjs from 'dayjs';


const BookingDetails = ({ getBookingDetails, user: { bookingDetails } }) => {

  useEffect(() => {
    getBookingDetails()
  },[])

  let bookedUserNames = [...new Set(bookingDetails?.map(bookings => bookings.user_name))].map(booking => ({
    text: booking, value: booking
  }));

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const options = {
    timeZone: 'Asia/Kolkata', // Set the time zone to IST
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };

  const [selectedDates, setSelectedDates] = useState([])
  const [filteredDates, setFilteredDates] = useState([])
  const [filteredBookingsByDate, setFilteredBookingsByDate] = useState(bookingDetails)

  console.log("filtered bookings by date", filteredBookingsByDate)
  //  Filter by date
  const monthsArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  const { RangePicker } = DatePicker;
  // Filter by Bookind date
  const onRangeChange = (dates) => {
    if (dates) {
      console.log('From: ', new Date(dates[0]), ', to: ', new Date(dates[1]), dates.map((date) => new Date(date)));
    } else {
      console.log('Clear');
    }

    // console.log("date list", values)

    const date = dates && new Date(new Date(dates[0]).getTime());
    const allSelectedDates = [];
    if (date) {
      while (date <= new Date(dates[1])) {
        allSelectedDates.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }
    }
    setSelectedDates(allSelectedDates)

    if (allSelectedDates.length === 0) {
      setFilteredBookingsByDate(bookingDetails)
    } else {
      let datesList = []
      let datesBookingsList = []
      bookingDetails.map((bookings) => {
        return (
          allSelectedDates.map((date) => {
            if (new Date(date).toDateString() === new Date(bookings.date_time).toDateString()) {
              datesList.push(new Date(bookings.date_time).toDateString())
              datesBookingsList.push(bookings)
            }
          })
        )
      })
      if (!datesBookingsList) {
        setFilteredBookingsByDate(bookingDetails)
      } else {
        setFilteredDates(datesList)
        setFilteredBookingsByDate(datesBookingsList)
      }
    }

  };
  const rangePresets = [
    { label: 'Today', value: [dayjs().add(0, 'd'), dayjs()] },
    { label: 'Yesterday', value: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')] },
    { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
    { label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
    { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
    { label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()] },
  ];

  // Filter by Sea walk date
  const onRangeChangeSWD = (dates) => {
    if (dates) {
      console.log('From: ', new Date(dates[0]), ', to: ', new Date(dates[1]), dates.map((date) => new Date(date)));
    } else {
      console.log('Clear');
    }

    // console.log("date list", values)

    const date = dates && new Date(new Date(dates[0]).getTime());
    const allSelectedDates = [];
    if (date) {
      while (date <= new Date(dates[1])) {
        allSelectedDates.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }
    }
    setSelectedDates(allSelectedDates)

    if (allSelectedDates.length === 0) {
      setFilteredBookingsByDate(bookingDetails)
    } else {
      let datesList = []
      let datesBookingsList = []
      bookingDetails.map((bookings) => {
        return (
          allSelectedDates.map((date) => {
            if (new Date(date).toDateString() === new Date(bookings.seawalk_date).toDateString()) {
              datesList.push(new Date(bookings.seawalk_date).toDateString())
              datesBookingsList.push(bookings)
            }
          })
        )
      })
      if (!datesBookingsList) {
        setFilteredBookingsByDate(bookingDetails)
      } else {
        setFilteredDates(datesList)
        setFilteredBookingsByDate(datesBookingsList)
      }
    }

  };

  const rangePresetsSWD = [
    { label: 'Last 30 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
    { label: 'Last 14 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
    { label: 'Last 7 Days', value: [dayjs().add(-90, 'd'), dayjs()] },
    { label: 'Yesterday', value: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')] },
    { label: 'Today', value: [dayjs().add(0, 'd'), dayjs()] },
    { label: 'Tomorrow', value: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')] },
    { label: 'Next 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
    { label: 'Next 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
    { label: 'Next 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
  ];


  const columns = [
    {
      title: 'Name',
      dataIndex: 'user_name',
      key: 'user_name',
      filters: bookedUserNames,
      filterSearch: true,
      onFilter: (value, record) => {
        // Perform an exact match on clientName
        return record.user_name.toLowerCase() === value.toLowerCase();
      },
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Booking ID',
      dataIndex: 'booking_id',
      key: 'booking_id',
      render: (text) => <a>{text}</a>,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Booking ID"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <button
              type="button"
              onClick={() => {
                setSelectedKeys([]);
                clearFilters();
              }}
              size="small"
              style={{ width: 90, border: "none" }}
            >
              Reset
            </button>
            <button
              type="button"
              onClick={() => confirm()}
              size="small"
              style={{
                width: 90,
                backgroundColor: "#1677ff",
                color: "white",
                borderRadius: "3px",
                border: "none"
              }}
            >
              Filter
            </button>
          </Space>
        </div>
      ),
      onFilter: (value, record) => record.booking_id.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: 'Booked On',
      dataIndex: 'date_time',
      key: 'date_time',
      render: (date) => <p className="m-0">{new Intl.DateTimeFormat('en-US', options).format(new Date(date))}</p>,
    },
    {
      title: 'Sea Walk Date',
      dataIndex: 'seawalk_date',
      key: 'seawalk_date',
      render: (date) => <p className="m-0">{new Date(date).toLocaleDateString()}</p>,
    },
    {
      title: 'No.Of Travellers',
      dataIndex: 'no_of_tourist',
      key: 'no_of_tourist',
    },
    {
      title: 'Payment Status',
      key: 'status',
      dataIndex: 'status',
      render: (_, { status }) => {
        let color;
        if (status === 'not paid') {
          color = 'volcano';
        }
        if (status === 'paid') {
          color = 'green';
        }
        if (status === 'pending') {
          color = 'geekblue';
        }
        return (
          <Tag color={color} key={status}>
            {status.toUpperCase()}
          </Tag>
        )
      }
    },
    {
      title: 'PDF',
      key: 'pdf',
      render: (_, bookingDetails) => (
        <Space size="middle">
          <PDFDownloadLink className="pt-3 download-bottom-btn" document={<MyDocument bookingDetails={bookingDetails} />} fileName={`SeaWalk in Andaman Ticket.pdf`}>
            {({ blob, url, loading, error }) =>
              loading ? 'Download PDF' : <button>Download PDF <img style={{ width: "15px" }} className='ms-2' src='/images/download-white.png' alt='download'></img></button>
            }
          </PDFDownloadLink>
        </Space>
      ),
    },
  ];

  const bookingDateRangePickerRef = useRef(null);
  const seaWalkDateRangePickerRef = useRef(null);

  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
    setFilteredBookingsByDate(bookingDetails);
    // Clear selection of RangePicker components
    // Reset the values of RangePicker components
    if (bookingDateRangePickerRef.current) {
      bookingDateRangePickerRef.current.value = [dayjs, dayjs];
    }

    if (seaWalkDateRangePickerRef.current) {
      seaWalkDateRangePickerRef.current.value = [dayjs, dayjs];
    }
  };

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const getColumnsWithFilterSorter = columns.map(column => ({
    ...column,
    filteredValue: filteredInfo[column.dataIndex] || null,
    sortOrder: sortedInfo.columnKey === column.dataIndex && sortedInfo.order,
  }));


  return (
    <Fragment>
      <Header />
      <SideNav />
      <div className='app-content'>
        <div id='booking-details'>
          <div className='p-2'>
            <h1 className='title'>Booking Details</h1>
            <div className='row m-0 pt-3 pb-3'>
              {/* <div className='col-md-6 col-lg-4 col-xl-3 p-0'>
                <label>Filter by Booking Date</label>
                <RangePicker ref={bookingDateRangePickerRef} presets={rangePresets} onChange={onRangeChange} placeholder={["Start Date", "End Date"]} format="DD/MM/YYYY" />
              </div> */}
              <div className='col-md-6 col-lg-4 col-xl-3 p-0'>
                <label>Filter by Sea Walk Date</label>
                <RangePicker ref={seaWalkDateRangePickerRef} presets={rangePresetsSWD} onChange={onRangeChangeSWD} placeholder={["Start Date", "End Date"]} format="DD/MM/YYYY" />
              </div>
              <div className='col-md-6 col-lg-4 col-xl-3  d-flex align-items-end'>
                <Button onClick={clearAll}>Clear</Button></div>
            </div>
            <Table
              // columns={columns}
              columns={getColumnsWithFilterSorter}
              dataSource={filteredBookingsByDate ? filteredBookingsByDate : bookingDetails}
              scroll={{ x: 1300 }}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
    </Fragment>

  )
}

BookingDetails.prototype = {
  getBookingDetails: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps, { getBookingDetails })(BookingDetails);