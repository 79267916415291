import React, { useState, useEffect } from 'react';
import "./SideNav.css";
import { Link, NavLink } from 'react-router-dom';
import { DownloadOutlined, ReloadOutlined } from '@ant-design/icons';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { FloatButton, Modal } from 'antd';

export const SideNav = () => {

  const [activeMenu, setActiveMenu] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onClickMenu = (menuId) => {
    setActiveMenu(menuId);
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  return (
    <div className='side-nav d-lg-block d-none'>
      <div className='d-flex justify-content-center align-items-center'>
        <a href="#" >
          <img className="logo" src='/images/sea-gold-logo.jpg' alt="logo"></img>
        </a>
      </div>
      <ul>
        <li><NavLink className={activeMenu === 'Dashboard' ? 'menu-link active' : 'menu-link bulk-menu'} to='/' onClick={() => onClickMenu('Dashboard')}>Dashboard</NavLink></li>
        <div className='menu-group customer'>
          <h6>Customer</h6>
          <li><NavLink className={activeMenu === 'booking-details' ? 'menu-link active' : 'menu-link'} to='/booking-details' onClick={() => onClickMenu('booking-details')}>- Booking Details</NavLink></li>
          <li><NavLink className={activeMenu === 'enquiry-details' ? 'menu-link active' : 'menu-link'} to='/enquiry-details' onClick={() => onClickMenu('enquiry-details')}>- Enquiry Details</NavLink></li>
        </div>
        {/* <hr></hr> */}
        <div className='menu-group travel-agency'>
          <h6>Travel Agency</h6>
          <li><NavLink className={activeMenu === 'agency-booking-details' ? 'menu-link active' : 'menu-link'} to='/agency-booking-details' onClick={() => onClickMenu('agency-booking-detailst')}>- Booking Details</NavLink></li>
          <li><NavLink className={activeMenu === 'agency-login-request' ? 'menu-link active' : 'menu-link'} to='/agency-login-request' onClick={() => onClickMenu('agency-login-request')}>- Agency Request</NavLink></li>
        </div>
        <li><NavLink className={activeMenu === 'bulk-download' ? 'menu-link active' : 'menu-link bulk-menu'} to='/bulk-download' onClick={() => onClickMenu('bulk-download')}>Bulk Download <DownloadOutlined /></NavLink></li>
        {/* <li><NavLink className={activeMenu === 'refund' ? 'menu-link active' : 'menu-link bulk-menu'} to='/refund' onClick={() => onClickMenu('refund')}>Refund <ReloadOutlined /></NavLink></li> */}
      </ul>

      <Modal title="For Technical Support" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p> If you have any doubts or clarrifications, do reach out to Development team at contact@zeek.digital</p>
      </Modal>
    </div>
  )
}
