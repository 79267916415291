import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, USER_LOADED, AUTH_ERROR } from "../actions/types";


const initialState = {
    adminToken: localStorage.getItem("adminToken"),
    isAuthenticated: false,
    loading: true,
    user:null,
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    console.log(type, payload)
    switch (type) {
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user: payload
            }
        case LOGIN_SUCCESS:
            localStorage.setItem("adminToken", payload.adminToken)
            return {
                ...state,
                ...payload,
                isAuthenticated: true,
                loading:false,
            }
        case AUTH_ERROR:
        case LOGIN_FAIL:
        case LOGOUT:
            localStorage.removeItem("adminToken");
            return {
                ...state,
                adminToken: null,
                isAuthenticated: false,
                loading:false
            }
        default:
            return state;
    }
}