export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";

export const LOGOUT = "LOGOUT";

export const GET_BOOKING_DETAILS = "GET_BOOKING_DETAILS";
export const GET_BOOKING_DETAILS_ERROR = "GET_BOOKING_DETAILS_ERROR";

export const GET_ENQUIRY_DETAILS = "GET_ENQUIRY_DETAILS";
export const GET_ENQUIRY_DETAILS_ERROR = "GET_ENQUIRY_DETAILS_ERROR";

export const GET_AGENT_BOOKING_DETAILS = "GET_AGENT_BOOKING_DETAILS";
export const GET_AGENT_BOOKING_DETAILS_ERROR = "GET_AGENT_BOOKING_DETAILS_ERROR";

export const GET_AGENT_REQUEST_DETAILS = "GET_AGENT_REQUEST_DETAILS";
export const GET_AGENT_REQUEST_DETAILS_ERROR = "GET_AGENT_REQUEST_DETAILS_ERROR";

export const GET_BOOKING_DETAILS_BY_DATE = "GET_BOOKING_DETAILS_BY_DATE";
export const GET_BOOKING_DETAILS_BY_DATE_ERROR = "GET_BOOKING_DETAILS_BY_DATE_ERROR";

export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const REFUND_DONE = "REFUND_DONE";
export const REFUND_ERROR = "REFUND_ERROR";
