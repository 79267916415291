import React from 'react'
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Navigate } from 'react-router-dom';

function PrivateRoute({component:Component ,auth:{isAuthenticated, loading}}) {
    if (loading) return <h1>Loading</h1>;
    if (isAuthenticated) return <Component />
    return <Navigate to='/login' />
}

PrivateRoute.prototype = {
    auth: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps)(PrivateRoute);