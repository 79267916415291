import React, { Fragment, useState } from 'react';
import { DatePicker, Select, Space, Form, Button, Table } from 'antd';
import Header from '../components/Header';
import { SideNav } from '../components/SideNav';
import { connect } from 'react-redux';
import { getBookingDetailsByDate } from './../actions/bulkdownload';
import { PropTypes } from 'prop-types';
import BulkDownloadPDF from '../components/ui/BulkDownloadPDF';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { DownloadOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

const BulkDownload = ({ getBookingDetailsByDate, bulkDownload: { bookingDetailsByDate } }) => {

    const [userType, setUserType] = useState();
    const [fromdate, setFromdate] = useState();
    const handleChange = (value) => {
        console.log(`selected ${value}`);
        setUserType(value);
    };

    const onFinish = (values) => {

        const fromDate = new Date(values.date).toLocaleDateString();
        const toDate = new Date(values.date).toLocaleDateString();
        console.log('Selected Date:', new Date(values.date).toLocaleDateString(), userType);
        setFromdate(fromDate);
        getBookingDetailsByDate({ fromDate, toDate, userType });

    };

    const columns = [
        {
            title: 'S.No',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Booking ID',
            dataIndex: 'booking_id',
            key: 'booking_id',
        },
        {
            title: 'Traveler Name',
            dataIndex: 'travelerName',
            key: 'travelerName',
        },
        {
            title: 'Traveler Gender',
            dataIndex: 'travelerGender',
            key: 'travelerGender',
        },
        {
            title: 'Traveler Age',
            dataIndex: 'travelerAge',
            key: 'travelerAge',
        },
        {
            title: 'Slot',
            dataIndex: 'booked_slot',
            key: 'booked_slot',
        },
    ];

    const data = bookingDetailsByDate?.map((booking) =>
        booking.tourist_details.map((traveler) => ({
            booking_id: booking.booking_id,
            travelerName: traveler.travelerName,
            travelerGender: traveler.travelerGender,
            travelerAge: traveler.travelerAge,
            booked_slot: booking.booked_slot,
            user_type: booking.user_type
        }))).flat().map((item, index) => ({ ...item, index }));

    return (
        <Fragment>
            <Header />
            <SideNav />
            <div className='app-content'> 
                <Form onFinish={onFinish} layout="vertical">
                    <h1 className='title ps-2'>Bulk Download</h1>
                    <div className='row m-0'>
                        <div className='col-md-6 col-lg-4 col-xl-3'>
                            <Form.Item name="date" label="Select Sea Walk Date" rules={[{ required: true, message: 'Please select a Sea Walk Date' }]}>
                                <DatePicker format={'DD/MM/YYYY'} placeholder="Sea Walk Date" style={{ width: '100%' }} />
                            </Form.Item>
                        </div>
                        <div className='col-md-6 col-lg-4 col-xl-3'>
                            <Form.Item name="type" label="Select User Type" rules={[{ required: true, message: 'Please select a User Type' }]}>
                                <Select
                                    placeholder="User Type"
                                    onChange={handleChange}
                                    options={[
                                        {
                                            value: 'Customer',
                                            label: 'Customer Bookings',
                                        },
                                        {
                                            value: 'Agent',
                                            label: 'Agent Bookings',
                                        },
                                        {
                                            value: 'All',
                                            label: 'All Bookings',
                                        },
                                    ]}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </div>
                        <div className='col-md-6 col-lg-4 col-xl-3 d-flex align-items-end'>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" style={{ width: 150 }}>
                                    Submit
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <PDFDownloadLink className='ms-3' document={<BulkDownloadPDF bookingDetails={data} date={fromdate} />} fileName={`Bookings for ${fromdate}`}>
                                    {({ blob, url, loading, error }) =>
                                        loading ? 'Download PDF' : <Button type="primary" icon={<DownloadOutlined />} style={{ width: 150 }} size={'middle'} >
                                            Download
                                        </Button>
                                    }
                                </PDFDownloadLink>
                            </Form.Item>
                        </div>
                        <div className='col-md-6 col-lg-4 col-xl-3'>

                        </div>
                    </div>
                </Form>



                <Table columns={columns} dataSource={data} scroll={{ x: 1300 }} />
                {/* {
                    data && <PDFViewer width={1000} height={1000}>
                        <BulkDownloadPDF bookingDetails={data} date={fromdate} />
                    </PDFViewer>
                } */}

            </div>
        </Fragment>
    )
}

BulkDownload.prototype = {
    getBookingDetailsByDate: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    bulkDownload: state.bulkdownload
})

export default connect(mapStateToProps, { getBookingDetailsByDate })(BulkDownload);