import React, { Fragment, useEffect, useState } from 'react';
import { Space, Table, Tag, Input, Button } from 'antd';
import Header from '../components/Header';
import { SideNav } from '../components/SideNav';
import { connect } from 'react-redux';
import { getAgentBookingDetails } from './../actions/agency';
import { PropTypes } from 'prop-types';
import MyDocument from '../components/ui/DownloadPDF';
import { PDFDownloadLink } from '@react-pdf/renderer';
import "./Dashboard.css";

const AgencyDetails = ({ getAgentBookingDetails, agent: { agentBookingDetails } }) => {
  useEffect(() => {
    getAgentBookingDetails()
  },[])

  let bookedAgentNames = [...new Set(agentBookingDetails?.map(bookings => bookings.agent_name))].map(booking => ({
    text: booking, value: booking
  }));
  let bookedAgentCompanyNames = [...new Set(agentBookingDetails?.map(bookings => bookings.agent_company_name))].map(booking => ({
    text: booking, value: booking
  }));

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const columns = [
    {
      title: 'Name',
      dataIndex: 'agent_name',
      key: 'agent_name',
      filters: bookedAgentNames,
      filterSearch: true,
      onFilter: (value, record) => {
        // Perform an exact match on clientName
        return record.user_name.toLowerCase() === value.toLowerCase();
      },
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Agency Name',
      dataIndex: 'agent_company_name',
      key: 'agent_company_name',
      filters: bookedAgentCompanyNames,
      filterSearch: true,
      onFilter: (value, record) => {
        // Perform an exact match on clientName
        return record.agent_company_name.toLowerCase() === value.toLowerCase();
      },
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Booking ID',
      dataIndex: 'booking_id',
      key: 'booking_id',
      render: (text) => <a>{text}</a>,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Booking ID"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <button
              type="button"
              onClick={() => {
                setSelectedKeys([]);
                clearFilters();
              }}
              size="small"
              style={{ width: 90, border: "none" }}
            >
              Reset
            </button>
            <button
              type="button"
              onClick={() => confirm()}
              size="small"
              style={{
                width: 90,
                backgroundColor: "#1677ff",
                color: "white",
                borderRadius: "3px",
                border: "none"
              }}
            >
              Filter
            </button>
          </Space>
        </div>
      ),
      onFilter: (value, record) => record.booking_id.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: 'Booked Date',
      dataIndex: 'date_time',
      key: 'date_time',
    },
    {
      title: 'Sea Walk Date',
      dataIndex: 'seawalk_date',
      key: 'seawalk_date',
    },
    {
      title: 'No.Of Travellers',
      dataIndex: 'no_of_tourist',
      key: 'no_of_tourist',
    },
    {
      title: 'Payment Status',
      key: 'status',
      dataIndex: 'status',
      render: (_, { status }) => {
        let color;
        if (status === 'not paid') {
          color = 'volcano';
        }
        if (status === 'paid') {
          color = 'green';
        }
        if (status === 'pending') {
          color = 'geekblue';
        }
        return (
          <Tag color={color} key={status}>
            {status.toUpperCase()}
          </Tag>
        )
      }
    },
    {
      title: 'PDF',
      key: 'pdf',
      render: (_, bookingDetails) => (
        <Space size="middle">
          <PDFDownloadLink className="pt-3 download-bottom-btn" document={<MyDocument bookingDetails={bookingDetails} />} fileName={`SeaWalk in Andaman Ticket.pdf`}>
            {({ blob, url, loading, error }) =>
              loading ? 'Download PDF' : <button>Download PDF <img style={{ width: "15px" }} className='ms-2' src='/images/download-white.png' alt='download'></img></button>
            }
          </PDFDownloadLink>
        </Space>
      ),
    },
  ];

  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const getColumnsWithFilterSorter = columns.map(column => ({
    ...column,
    filteredValue: filteredInfo[column.dataIndex] || null,
    sortOrder: sortedInfo.columnKey === column.dataIndex && sortedInfo.order,
  }));

  return (
    <Fragment>
      <Header />
      <SideNav />
      <div className='app-content'>
        <div id='booking-details'>
          <div className='p-2'>
            <h1 className='title'>Travel Agency Booking Details</h1>
            <Button onClick={clearAll} className='mb-3'>Clear All Filters</Button>

            <Table
              // columns={columns}
              columns={getColumnsWithFilterSorter}
              dataSource={agentBookingDetails}
              scroll={{ x: 1300 }}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

AgencyDetails.prototype = {
  getAgentBookingDetails: PropTypes.func.isRequired,
  agent: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  agent: state.agency
})

export default connect(mapStateToProps, { getAgentBookingDetails })(AgencyDetails);